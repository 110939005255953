/*@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700);*/
/*@import url("https://cdnjs.cloudflare.com/ajax/libs/hack-font/3.3.0/web/hack.min.css");*/
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");


/* quattrocento-sans-regular - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('assets/fonts/quattrocento-sans-v18-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/quattrocento-sans-v18-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-sans-italic - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('assets/fonts/quattrocento-sans-v18-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/quattrocento-sans-v18-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-sans-700 - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('assets/fonts/quattrocento-sans-v18-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/quattrocento-sans-v18-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quattrocento-sans-700italic - latin */
@font-face {
  font-family: 'Quattrocento Sans';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('assets/fonts/quattrocento-sans-v18-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/quattrocento-sans-v18-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Hack';
  src: url('assets/fonts/hack-regular.woff2?sha=3114f1256') format('woff2'), url('assets/fonts/hack-regular.woff?sha=3114f1256') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Hack';
  src: url('assets/fonts/hack-bold.woff2?sha=3114f1256') format('woff2'), url('assets/fonts/hack-bold.woff?sha=3114f1256') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Hack';
  src: url('assets/fonts/hack-italic.woff2?sha=3114f1256') format('woff2'), url('assets/fonts/hack-italic.woff?sha=3114f1256') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Hack';
  src: url('assets/fonts/hack-bolditalic.woff2?sha=3114f1256') format('woff2'), url('assets/fonts/hack-bolditalic.woff?sha=3114f1256') format('woff');
  font-weight: 700;
  font-style: italic;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 80px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bottom-align-text {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15,
.col-xs-25,
.col-sm-25,
.col-md-25,
.col-lg-25,
.col-xl-25 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}
@media (min-width: 576px) {
  .col-sm-15 {
    flex: 20%;
    max-width: 20%;
    float: left;
  }
  .col-sm-25 {
    flex: 40%;
    max-width: 40%;
    float: left;
  }
  .sq-sm-15 {
    height: 240px;
  }
  .sq-sm-25 {
    height: 280px;
  }
  .sq-sm-4 {
    height: 230px;
  }
  .sq-sm-8 {
    height: 500px;
  }
}
@media (min-width: 768px) {
  .col-md-15 {
    flex: 20%;
    max-width: 20%;
    float: left;
  }
  .col-md-25 {
    flex: 40%;
    max-width: 40%;
    float: left;
  }
  .sq-md-15 {
    height: 174px;
  }
  .sq-md-25 {
    height: 368px;
  }
  .sq-md-3 {
    height: 225px;
  }
  .sq-md-6 {
    height: 470px;
  }
}
@media (min-width: 992px) {
  .col-lg-15 {
    flex: 20%;
    max-width: 20%;
    float: left;
  }
  .col-lg-25 {
    flex: 40%;
    max-width: 40%;
    float: left;
  }
  .sq-lg-15 {
    height: 214px;
  }
  .sq-lg-25 {
    height: 448px;
  }
}
@media (min-width: 1200px) {
  .col-xl-15 {
    flex: 20%;
    max-width: 20%;
    float: left;
  }
  .col-xl-25 {
    flex: 40%;
    max-width: 40%;
    float: left;
  }
  .sq-xl-15 {
    height: 214px;
  }
  .sq-xl-25 {
    height: 448px;
  }
}
.navbar-brand {
  color: #eee;
}
.container {
  font-family: 'Quattrocento Sans', sans-serif;
  font-size: 14px;
}
.event {
  background-color: #79161c;
  color: white;
}
.table > tbody > tr > th {
  border-top: none;
}
.table > tbody > tr > td > a  {
text-decoration: none;
}
.panel-event {
  border-color: #79161c;
}
/* .panel-event .card-header {
  background-color: #79161c;
  color: white;
} */
.panel-event .card-body,
.panel-event list-group-item {
  background-color: #fff;
  color: #000;
}
.panel-event caption,
.panel-event .table > tbody > tr > th {
  color: #79161c;
  border-top: none;
  padding-top: 20px;
}
.panel-event b,
.panel-event strong {
  color: rgb(33, 63, 39);
}
.panel-event ul > li > a {
  color: white;
}
.panel-event .nav > li > a:hover,
.panel-event .nav > li > a:focus {
  text-decoration: none;
  background-color: #555;
}
#anmeldung {
  margin-top: 20px;
  min-height: 1000px;
}
#content {
  background-color: #8a8f7f;
  color: white;
  padding: 10px;
}
#news,
#news2 {
  background-color: #79161c;
  color: white;
  cursor: pointer;
  min-height: 130px;
}
#news a,
#news2 a {
  color: white;
  text-decoration: none;
}

#news2 {padding: 10px;}

@media (min-width: 576px) {
  #topleft {
    background: url('assets/images/elsdance/skulptur-sq.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
  #bio {
    background: url('assets/images/elsdance/elske-sitting.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
}
@media (min-width: 768px) {
  #content {
    background-color: #8a8f7f;
    color: white;
  }
  #topleft2 {
    background: url('assets/images/elsdance/skulptur.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
  #redgreen {
    background: url('assets/images/elsdance/rotgrun-sq.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
  #group {
    background: url('assets/images/elsdance/gruppe-sq.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
  #hands {
    background: url('assets/images/elsdance/hands.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
}
@media (min-width: 992px) {
  #topleft {
    background: url('assets/images/elsdance/skulptur-sq.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
  #group {
    background: url('assets/images/elsdance/gruppe.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
}
@media (min-width: 1200px) {
  #redgreen {
    background: url('assets/images/elsdance/rotgrun.jpg') 0 0 no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
  }
}
.row1,
.row11 {
  margin-bottom: 20px;
}
.bio.ng-hide {
  -webkit-transition: -webkit-transform 0.7s ease-in-out;
  -moz-transition: -moz-transform 0.7s ease-in-out;
  -o-transition: -o-transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.bio.ng-hide-add.ng-hide-add-active,
.bio.ng-hide-remove.ng-hide-remove-active {
  -webkit-transition: background-position-y ease 0.5s;
  transition: max-height ease 1s;
}
.bio.ng-hide {
  max-height: 0px;
}
.xslide.ng-leave {
  position: absolute;
  -webkit-animation: fadeOut 1s;
  -moz-animation: fadeOut 1s;
  -ms-animation: fadeOut 1s;
  animation: fadeOut 1s;
}
.xslide.ng-enter {
  position: absolute;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
.anmeldung h3 {
  color: darkblue;
}
.angular-google-map-container {
  height: 400px;
}
/*
#biography {
	.zoomPic("./images/bio.jpg");
	height: 760px;

}*/
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 30px;
  background-color: #222;
}
.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}



.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.navbar-brand {color: rgb(24, 23, 23)}
